"use client";

import { useEffect, useMemo, type PropsWithChildren } from "react";

import { StatsigClient, StatsigUser } from "@statsig/js-client";
import { StatsigProvider } from "@statsig/react-bindings";

type Props = PropsWithChildren & {
    readonly clientSdkKey: string;
    readonly user: StatsigUser;
    readonly values: string | null;
};

let globalClient: StatsigClient | null = null;

export default function BootstrappedStatsigProvider({ clientSdkKey, user, values, children }: Props): JSX.Element {
    const client = useMemo(() => {
        if (values === null) {
            return null;
        }
        const client = new StatsigClient(clientSdkKey, user);
        client.dataAdapter.setData(values);
        client.initializeSync();
        return client;
    }, [clientSdkKey, user, values]);

    useEffect(() => {
        globalClient = client;
        return () => {
            globalClient = null;
        };
    }, [client]);

    return !!client ? <StatsigProvider client={client}>{children}</StatsigProvider> : <>{children}</>;
}

export const getGlobalStatsigClient = (): StatsigClient | null => globalClient;
