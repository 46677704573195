export class RegexUtils {
    static creditCardRegex = /^\d{4}[- ]?(\d{4,6})[- ]?(\d{4,5})([- ]?(\d{4}))?$/;
    static creditCardNameRegex = /^[A-Z]+(?:[.' -][A-Z]+)*$/;
    static firstLastRegex = /^[a-zA-Z0-9.]+( [a-zA-Z0-9.]+)+$/;

    static hasLettersRegex = /[a-zA-Z]/;
    static noEmptyStringRegex = /^(.*)?\S+(.*)?$/;
    static noNumbersRegex = /^([^0-9]*)$/;
    static petNameRegex = /^[a-zA-Z0-9\s]*$/;
    static phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    static uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    static zipRegex = /^\d{5}$/;

    // Canada
    static postalCodeCARegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
    static microchipRegex = /^([0-9]{9,15})$/; // 9 to 15 digits
}
