import Cookies from "js-cookie";
import { Quote } from "../types/Quote.interface";
import { SegmentUserIdentifier, BrowserExtraData, BuilderUserIdentifier, Partner247 } from "spot-types/misc/BrowserExtraData";
import { IdentifyTraitsTransforms } from "spot-types/vendor/enhanced-commerce/IdentifyTraitsTransforms";
import { QuoteDataUtils } from "./QuoteDataUtils";
import { StorageUtils } from "./StorageUtils";
import { UIUtils } from "./UIUtils";
import { getGlobalStatsigClient } from "../contexts/BootstrappedStatsigProvider";
import { PublicConfig } from "../PublicConfig";

type SegmentEcommerceEvents =
    | "Product Clicked"
    | "Product Viewed"
    | "Product Added"
    | "Product Removed"
    | "Cart Viewed"
    | "Checkout Started"
    | "Checkout Step Viewed"
    | "Checkout Step Completed"
    | "Payment Info Entered"
    | "Order Completed"
    | "Order Updated"
    | "Order Refunded"
    | "Order Cancelled";

type SegmentOtherEvents = "Continue Clicked";

type SegmentEvents = SegmentEcommerceEvents | SegmentOtherEvents;

type EventProperties = {
    [x: string]: any;
};

type SpotSession = {
    browserSessionId?: string;
    testUserId?: string;
    variantId?: string;
};

type StatsigExposureData = {
    statsig?: {
        userID?: string;
        layer?: string;
        ruleID?: string;
        allocatedExperimentName?: string;
    };
};

export type AllBrowserExtraData = BrowserExtraData & SpotSession & StatsigExposureData;

export class AnalyticsUtils {
    static getSegmentIds(email?: string): SegmentUserIdentifier | undefined {
        if (typeof window === `undefined`) {
            return undefined;
        }

        const anonymousId = Cookies.get("ajs_anonymous_id");
        const userId = Cookies.get("ajs_user_id");

        let finalUserId = userId;

        if (userId && !!email) {
            try {
                const decodedUserId = window.atob(userId);
                if (decodedUserId !== email) {
                    finalUserId = window.btoa(email);
                    window.analytics?.alias(finalUserId, userId);
                }
            } catch (error) {
                console.error(`Error decoding userId: ${error}`);
                finalUserId = window.btoa(email);
            }
        } else if (!!email) {
            finalUserId = window.btoa(email);
        }

        return {
            userId: finalUserId ?? "",
            anonymousId: anonymousId ?? ""
        };
    }

    static identifyUser(userId: string, quote: Quote) {
        const identifyPayload = IdentifyTraitsTransforms.petQuoteToIdentifyTraits(QuoteDataUtils.quoteToPetQuote(quote));
        window.analytics?.identify(userId, identifyPayload);
        if (!!window.DD_RUM) {
            window.DD_RUM.onReady(() => {
                window.DD_RUM?.setUser({ id: userId, ...identifyPayload });
            });
        }
    }

    static trackSegmentEvent(event: SegmentEvents, properties?: EventProperties) {
        try {
            window.analytics?.track(event, properties);
        } catch (error) {
            console.error(`Error tracking Segment event: `, event);
        }

        if (window.DD_RUM) {
            try {
                window.DD_RUM.onReady(() => {
                    window.DD_RUM?.addAction(event, properties);
                });
            } catch (error) {
                console.error(`Error tracking Datadog event: `, event);
            }
        }
    }

    static buildSegmentLocationObject() {
        if (typeof window === "undefined" || typeof document === "undefined") return {};

        const { location }: { location: Location } = window;

        const title = document?.title || `Start Your Free Quote | Spot Pet Insurance`;
        const referrer = document?.referrer || undefined;

        const { pathname = ``, search = ``, href = `` } = location || {};

        const locationObj = {
            path: pathname,
            referrer,
            search,
            title,
            url: href
        };

        return locationObj;
    }

    static initSegmentPageCall(attempts = 0) {
        if (!!window.analytics?.page) {
            window.analytics?.page(undefined, undefined, AnalyticsUtils.buildSegmentLocationObject());
            return;
        }

        if (attempts > 0) {
            setTimeout(() => AnalyticsUtils.initSegmentPageCall(attempts - 1), 500);
        }

        if (attempts === 0) {
            console.warn(`Unable to locate analytics.js`);
        }
    }

    static getQueryParams = (quoteParams?: Record<string, string>) => {
        const currentParams = typeof window !== "undefined" ? Object.fromEntries(new URLSearchParams(window.location.search)) : {};
        const mergedParams = { ...quoteParams, ...currentParams };

        return mergedParams;
    };

    static getBuilderSessionId(): string {
        const currentSessionId = Cookies.get("builderSessionId") ?? "";
        return currentSessionId;
    }

    static getBuilderTests(): BuilderUserIdentifier["tests"] {
        const allCookies = Cookies.get();
        const filteredCookies = Object.entries(allCookies).filter(([name, value]) => name.includes("builder.tests."));

        const tests: { [testId: string]: string } = {};
        filteredCookies.forEach(([name, value]) => {
            const testId = name.replace("builder.tests.", "");
            tests[testId] = value;
        });

        return tests;
    }

    static getBuilderObject(): BuilderUserIdentifier {
        return {
            sessionId: AnalyticsUtils.getBuilderSessionId(),
            tests: AnalyticsUtils.getBuilderTests()
        };
    }

    static getPartner247Object(): Partner247 {
        const queryParams = AnalyticsUtils.getQueryParams();

        return {
            visitor_id: Cookies.get("sn.vi") ?? "",
            ad_request_id: Cookies.get("tfs-pzn-adreqid") ?? queryParams["adreqid"] ?? ""
        };
    }

    static getSpotSessionObject(testUserId?: string): SpotSession {
        const queryParams = new URLSearchParams(window.location.search);
        const browserSessionId = StorageUtils.getItem("browserSessionId") ?? undefined;
        const updatedTestUserId = testUserId ?? UIUtils.getCaseInsensitiveValue(queryParams, "tuid") ?? Cookies.get("tuid");

        return {
            browserSessionId,
            testUserId: updatedTestUserId
        };
    }

    static getUserAgent(): string {
        return typeof window !== "undefined" ? window.navigator.userAgent : "";
    }

    static getStatsigExposureData(layerId: string): StatsigExposureData | undefined {
        const statsigClient = getGlobalStatsigClient();
        if (!statsigClient) {
            return;
        }
        const testLayer = statsigClient.getLayer(layerId);
        const testUserId = statsigClient.getContext().user?.userID;
        return {
            statsig: {
                userID: testUserId ?? "",
                layer: testLayer?.name ?? "",
                ruleID: testLayer?.ruleID ?? "",
                allocatedExperimentName: testLayer?.__evaluation?.allocated_experiment_name ?? ""
            }
        };
    }

    static sendDataDogAction(actionName: string, actionProperties?: Record<string, any>) {
        if (typeof window !== "undefined" && window.DD_RUM) {
            window.DD_RUM.onReady(() => {
                window.DD_RUM?.addAction(actionName, actionProperties);
            });
        }
    }

    static sendDataDogLog(logLevel: "debug" | "info" | "warn" | "error" = "info", message: string, logProperties?: Record<string, any>) {
        if (typeof window !== "undefined" && window.DD_LOGS) {
            window.DD_LOGS.onReady(() => {
                window.DD_LOGS?.logger[logLevel](message, logProperties);
            });
        }
    }

    static constructExtra(quoteParams?: Record<string, string>, email?: string, testUserId?: string): AllBrowserExtraData {
        return {
            queryParams: AnalyticsUtils.getQueryParams(quoteParams),
            userAgent: AnalyticsUtils.getUserAgent(),
            segment: AnalyticsUtils.getSegmentIds(email),
            builder: {
                ...AnalyticsUtils.getBuilderObject()
            },
            partner247: {
                ...AnalyticsUtils.getPartner247Object()
            },
            ...AnalyticsUtils.getSpotSessionObject(testUserId),
            ...AnalyticsUtils.getStatsigExposureData(PublicConfig.STATSIG_LAYER_ID_MAIN)
        };
    }
}
