import React from "react";
import { z } from "zod";
import { ToastPosition } from "react-hot-toast";

// Notification types
const notificationTypes = ["toast", "modal"] as const;

// Frequency
const frequencyValues = ["hourly", "daily", "weekly", "monthly", "yearly"] as const;
const frequencySchema = z.enum(frequencyValues);
export type NotificationFrequency = (typeof frequencyValues)[number];

export const notificationSchema = z.object({
    id: z.string(),
    type: z.enum(notificationTypes),
    lastClosed: z.string().datetime(),
    frequency: frequencySchema.optional()
});

interface BaseNotification {
    id: string;
    message: React.ReactNode;
    visible: boolean;
    lastClosed?: string; // as ISO date string
    frequency?: NotificationFrequency;
    onClose?: () => void;
    isCloseDisabled?: boolean;
}

interface ToastNotification extends BaseNotification {
    type: "toast";
    duration: number;
    position: ToastPosition;
}

interface ModalNotification extends BaseNotification {
    type: "modal";
}

export type Notifications = {
    toasts?: ToastNotification[];
    modals?: ModalNotification[];
};
