import storage from "local-storage-fallback";

export class StorageUtils {
    static allItems = storage;

    static setItem(key: string, value: string) {
        return storage.setItem(key, value);
    }
    static getItem(key: string) {
        return storage.getItem(key);
    }
    static removeItem(key: string) {
        return storage.removeItem(key);
    }
    static clear() {
        return storage.clear();
    }
}
